import { Facts } from "components/facts";
import { Container } from "components/container";

import { getEdgeSettings } from "components/wrapper/components";
import { FC } from "react";
import { Fact, WrapperComponentProps } from "constants/types";
import { getSpeakingId } from "helpers/text-processing";

interface FactsWrapperProps extends WrapperComponentProps {
  fact: [Fact];
}

const FactsWrapper: FC<FactsWrapperProps> = (props) => {
  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={anchorNavId ? anchorNavId : `component__facts-${props.index}`}
      className="section component__facts"
    >
      <Facts facts={props.fact} />
    </Container>
  );
};

export default FactsWrapper;
